import React, {useEffect, useState} from 'react';
import ReactGA4 from 'react-ga4';

import reportWebVitals from './reportWebVitals';

import './App.scss';

import Image from './components/image';
import Project from './components/project';

function App() {
  const [isPretty, setIsPretty] = useState(true);

  useEffect(() => {
    document.body.classList.toggle('prettified', isPretty);
  },[isPretty])

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  useEffect(() => {
    ReactGA4.initialize('G-JZT4WRH1Q4');

    type cwvData = {
      id: string;
      name: string;
      value: number;
    };

    reportWebVitals(({ id, name, value }: cwvData) =>
      ReactGA4.event({
        action: name,
        category: 'Web Vitals',
        label: id,
        nonInteraction: true,
        value: Math.round(
          name === 'CLS' ? value * 1000 : value
        ),
      })
    );
  }, []);

  return (
    <div className="App">
      <header className="section section--intro">
      <br />

      <button onClick={()=> setIsPretty(!isPretty)} className="btn btn--toggle-css">
        <span>Toggle Styles</span>
      </button>

      <br /><br />
      
      <div className="header__wrap-confetti">
        <div className="header__wrap-img">
          <Image imgPath="/images/todd.jpg" width="220" height="220" altText="Todd Carpenter's Avatar" className="header__img" />
        </div>
      </div>
      
      <h1>Hi, I'm Todd.</h1>

      <p>I'm a <strong>web developer</strong> in Philadelphia. I enjoy working on the <strong>front end</strong> of the web.</p>
        
      {/*<p>If you're in need of a <strong>front end developer</strong>, <a href="mailto:hi@toddl.es" className="link link--header">get in touch</a>.</p>*/}

      <p>Find me on 
        <a href="https://www.linkedin.com/in/toddbc/" target="_blank" rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="#0a66c2" className="icon icon--linkedin" width="24" height="24" focusable="false">
            <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
          </svg>
        </a> 
        &amp; 
        <a href="https://www.instagram.com/timmytodd/" target="_blank" rel="noreferrer">
          <svg aria-label="Instagram" className="icon icon--instagram" color="rgb(0, 0, 0)" fill="rgb(0, 0, 0)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Instagram</title>
            <path d="M12 2.982c2.937 0 3.285.011 4.445.064a6.087 6.087 0 0 1 2.042.379 3.408 3.408 0 0 1 1.265.823 3.408 3.408 0 0 1 .823 1.265 6.087 6.087 0 0 1 .379 2.042c.053 1.16.064 1.508.064 4.445s-.011 3.285-.064 4.445a6.087 6.087 0 0 1-.379 2.042 3.643 3.643 0 0 1-2.088 2.088 6.087 6.087 0 0 1-2.042.379c-1.16.053-1.508.064-4.445.064s-3.285-.011-4.445-.064a6.087 6.087 0 0 1-2.043-.379 3.408 3.408 0 0 1-1.264-.823 3.408 3.408 0 0 1-.823-1.265 6.087 6.087 0 0 1-.379-2.042c-.053-1.16-.064-1.508-.064-4.445s.011-3.285.064-4.445a6.087 6.087 0 0 1 .379-2.042 3.408 3.408 0 0 1 .823-1.265 3.408 3.408 0 0 1 1.265-.823 6.087 6.087 0 0 1 2.042-.379c1.16-.053 1.508-.064 4.445-.064M12 1c-2.987 0-3.362.013-4.535.066a8.074 8.074 0 0 0-2.67.511 5.392 5.392 0 0 0-1.949 1.27 5.392 5.392 0 0 0-1.269 1.948 8.074 8.074 0 0 0-.51 2.67C1.012 8.638 1 9.013 1 12s.013 3.362.066 4.535a8.074 8.074 0 0 0 .511 2.67 5.392 5.392 0 0 0 1.27 1.949 5.392 5.392 0 0 0 1.948 1.269 8.074 8.074 0 0 0 2.67.51C8.638 22.988 9.013 23 12 23s3.362-.013 4.535-.066a8.074 8.074 0 0 0 2.67-.511 5.625 5.625 0 0 0 3.218-3.218 8.074 8.074 0 0 0 .51-2.67C22.988 15.362 23 14.987 23 12s-.013-3.362-.066-4.535a8.074 8.074 0 0 0-.511-2.67 5.392 5.392 0 0 0-1.27-1.949 5.392 5.392 0 0 0-1.948-1.269 8.074 8.074 0 0 0-2.67-.51C15.362 1.012 14.987 1 12 1Zm0 5.351A5.649 5.649 0 1 0 17.649 12 5.649 5.649 0 0 0 12 6.351Zm0 9.316A3.667 3.667 0 1 1 15.667 12 3.667 3.667 0 0 1 12 15.667Zm5.872-10.859a1.32 1.32 0 1 0 1.32 1.32 1.32 1.32 0 0 0-1.32-1.32Z"></path>
          </svg>
        </a> 
        <br />
        <br />
      </p>
    </header>

    <main className="section">

      <h2>Highlighted Projects</h2>

      <Project 
        title="LuckyVitamin"
        subcopy="LuckyVitamin is an e-commerce health &amp; wellness company that sells vitamins, supplements, and other health products."
        tech={["React", "Typescript", "CSS", ".NET", "AX"]}
        videoSrc="/videos/lucky-vitamin/LuckyVitamin_walk-through_high-res_cropped_compressed" />

      <Project 
        title="Stainmaster"
        subcopy="Room visualizer &amp; customizer for their Carpet Square product."
        link="https://toddl.es/portfolio/stainmaster/room-select.html"
        tech={["Javascript", "CSS", "Sitecore", ".NET", "Drag & Drop"]}
        imsSrc="/images/portfolio/stainmaster-room-visualizer.jpg"
        imgAlt="Stainmaster Room Visualizer for Carpet Squares" />

      <Project 
        title="Friendlys"
        subcopy="Built for their restaurant division to allow user's to browse all menu items and developed their campaign assets."
        tech={["Javascript", "CSS", "PHP", "Wordpress", "ACF"]}
        imsSrc="/images/portfolio/friendlys.jpg"
        imgAlt="Friendly's website" />

      <Project 
        title="Sila"
        subcopy="Heating, cooling, plumbing & electrical company's client facing website to learn about their services & schedule work."
        link="https://philadelphia.sila.com/"
        linkTxt="Visit Website"
        tech={["React", "CSS", "Wordpress API", "ACF"]}
        imsSrc="/images/portfolio/sila.jpg"
        imgAlt="Sila Heating and Cooling" />

      <Project 
        title="Valspar"
        subcopy="In-store touchscreen at Lowe's to help customers choose their perfect color."
        tech={["Javascript", "CSS", "Touchscreen", "Windows"]}
        imsSrc="/images/portfolio/valspar-touchscreen.jpg"
        imgAlt="Valspar In-Store Touchscreen" />

      <Project 
        title="Steve's Ice Cream"
        subcopy="Company website for artisan ice cream from Brooklyn."
        link="https://toddl.es/portfolio/steves-ice-cream/"
        tech={["Javascript", "CSS", "HTML", "Wordpress"]}
        imsSrc="/images/portfolio/steves.jpg"
        imgAlt="Steve's Ice Cream's website" />

      {/*<Project 
        title="EastPenn"
        link="https://www.eastpennmanufacturing.com/"
        linkTxt="Visit Website"
        subcopy="Battery manufacturer site to catalog products & business resources with a customized CMS."
        tech={["Javascript", "CSS", "PHP", "Wordpress", "ACF"]}
        imsSrc="/images/portfolio/eastpenn.jpg"
  imgAlt="Valspar In-Store Touchscreen" />*/}
    </main>

    <footer className="section section--footer ">
      <p>Say <a href="mailto:hi@toddl.es" className="link">hi@toddl.es</a><br />
          <span className="json"><em>Making JSON look pretty since 2008</em> <span className="heart">&hearts;</span></span></p>
    </footer>
    </div>
  );
}

export default App;
