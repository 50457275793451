import React from 'react';

interface iProject {
  imgAlt?: string,
  imsSrc?: string,
  link?: string,
  linkTxt?: string,
  subcopy: string,
  tech: any,
  title: string,
  videoSrc?: string
}

const Project: React.FC<iProject> = ({imgAlt, imsSrc, link, linkTxt, subcopy, tech, title, videoSrc}) => {
  const techList = tech.map((techItem:any, index:number) => {
    return (
      <li key={index + techItem} className="portfolio__item">{techItem}</li>
    );
  });
  return (
    <section className="portfolio">
      <h3 className="portfolio__title"><em>{title}</em></h3>

      <p className="portfolio__copy">{subcopy}</p>

      {link ? <p><a href={link} target="_blank" rel="noreferrer" className="btn btn--link">{linkTxt ? linkTxt : "See Demo"}</a></p> : ""}

      <p className="portfolio__stack"><strong>Technologies</strong></p>

      <ul className="portfolio__list">
        {techList}
      </ul>

      {imsSrc ? <img src={imsSrc} alt={imgAlt} width="340" className="portfolio__img" /> : ""}

      {videoSrc ? <video width="340" controls poster="/videos/lucky-vitamin/poster.jpg" autoPlay loop muted className="portfolio__video"><source src={videoSrc + ".mp4"} type="video/mp4" /><source src={videoSrc + ".ogg"} type="video/ogg" /><img src="/images/portfolio/luckyvitamin-1.jpg" alt="LuckyVitamin Homepage" width="340" className="slider__img" /><img src="/images/portfolio/luckyvitamin-2.jpg" alt="LuckyVitamin Listing Page" width="340" className="slider__img" /><img src="/images/portfolio/luckyvitamin-3.jpg" alt="LuckyVitamin Product Detail" width="340" className="slider__img" /><img src="/images/portfolio/luckyvitamin-4.jpg" alt="LuckyVitamin Cart" width="340" className="slider__img" /></video> : ""}

      <hr className="portfolio__rule" />
    </section>
  );
}

export default Project;