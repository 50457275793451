import React from 'react';

interface iImage {
    altText: string,
    className: string,
    height: string,
    imgPath: string,
    loading?: any,
    width: string
}

const Image: React.FC<iImage> = ({ altText, className, height, imgPath, loading = "eager", width }) => {
    const objImgAttributes = {
        className,
        height,
        width,
        loading
    };

    if(!imgPath) return <></>;

    return <img src={imgPath} alt={altText} {...objImgAttributes} />;
};

export default Image;